<template>
  <div class="card">
    <div class="row items-center">
      <!-- <div class="col-2 description q-mr-sm q-pa-xs">
        <div class="row items-center">THIS MONTH</div>
        
        <SelectField
          v-model="month"
          hide-border
          :is-clearable="false"
          :options="monthList"
          min-height="0px"
        />
      </div>

      <div id="separator" class="is-vertical"></div> -->

      <!-- <div class="col-1 description q-mx-sm q-pa-sm">
        <div class="row items-center">TYPE</div>
        
        <SelectField
          v-model="type"
          hide-border
          :is-clearable="false"
          :options="typeList"
          min-height="0px"
        />
      </div>

      <div id="separator" class="is-vertical"></div> -->

      <div class="col description q-mx-sm q-pa-xs">
        <div class="row q-mt-sm">
          <!-- <div class="q-mx-sm" style="color: #00bcd4">All Filter</div> -->

          <!-- <div class="chip">
            <div class="q-mr-sm">Workflow</div>

            <BaseIcon name="eva-close" />
          </div>

          <div class="chip">
            <div class="q-mr-sm">Workspace</div>

            <BaseIcon name="eva-close" />
          </div> -->
          <div class="col-auto">
            <SelectField
              :value="workflowId"
              :options="workflowList"
              is-clearable
              class="fieldList q-mr-md q-mb-sm"
              @input="updateWorkflowId"
            >
            </SelectField>
          </div>

          <template v-for="(field, index) in filter">
            <div :key="field.id" class="col-auto">
              <div class="row">
                <div class="col-auto">
                  <SelectField
                    v-model="filter[index]"
                    :options="formFields"
                    :is-clearable="false"
                    class="fieldList q-mb-sm"
                    @input="$emit('update:filter', filter)"
                  >
                  </SelectField>
                </div>
                <div class="col-auto q-mt-sm">
                  <BaseActionButton
                    v-tooltip.top="'remove filter'"
                    icon="mdi-close"
                    class="plus q-mx-sm"
                    size="14px"
                    is-flat
                    color="red"
                    @click="removeFilter(index)"
                  />
                </div>
              </div>
            </div>
          </template>

          <BaseActionButton
            v-if="superUser && false"
            v-tooltip.top="'add filter'"
            icon="mdi-plus"
            color="secondary"
            class=""
            @click="addFilter"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Action from "@/components/common/Action.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
// import { form } from "@/api/factory.js";

export default {
  name: "WorkflowFilter",

  components: { SelectField },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },
    workflowList: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    formFields: {
      type: Array,
      default: () => [],
    },
    superUser: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      month: "Feb - March",
      monthList: [
        {
          id: 1,
          label: "Feb - March",
          value: "Feb - March",
        },
      ],
      type: "counts",
      typeList: [
        {
          id: 1,
          label: "counts",
          value: "counts",
        },
      ],
    };
  },

  watch: {
    workflowId: {
      immediate: true,
      deep: true,
      handler() {
        if (this.workflowId) {
          // this.getForm();
        }
      },
    },

    formFields: {
      immediate: true,
      deep: true,
      handler() {
        if (this.formFields.length) {
          this.formFields.forEach((field) => {
            if (this.filter.length < 2 && this.superUser) {
              if (field.type === "SINGLE_SELECT") {
                this.filter.push(field.id);
              }
            }
          });
        }
      },
    },
  },

  methods: {
    isStatelessField(fieldType) {
      return ["LABEL", "DIVIDER", "PARAGRAPH", "TABLE"].includes(fieldType);
    },

    updateWorkflowId(value) {
      if (value) {
        this.$emit("update:workflowId", value);
      } else {
        this.$emit("update:workflowId", 0);
      }
    },

    addFilter() {
      this.filter.push("");
    },

    removeFilter(index) {
      this.filter.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  //   background-color: #fff;
  // background-color: #dff3f6;
  border-radius: 4px;
  //   height: 100%;
  // border: 1px solid $secondary;
  border-style: none solid none none;
  border: 1px solid var(--divider-color);
  // margin-right: 15px;

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }
}

#separator.is-vertical {
  height: auto;
  width: 1px;
}

#separator {
  height: 1px;
  border: 0;
  align-self: stretch;
  background-color: var(--divider-color);
}

.plus {
  height: 20px !important;
  width: 20px !important;
}

.chip {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.item-list {
  padding: 4px 4px 4px 8px;
  margin: 0px 8px;
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;

  .label {
    flex: 1;
    color: var(--secondary);
    margin-right: 8px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }
}
</style>
<style lang="scss">
#select-field.fieldList {
  .select {
    min-height: 0px !important;
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }
}
</style>
